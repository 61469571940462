<template>
  <div class="container-fluid container-fluid-1">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <slot name="btn-actions" />
        <b-button variant="outline-secondary btn_header" @click="modalSetting">
          <b-icon-gear />
        </b-button>
      </div>
      <div class="title">{{ title }}</div>
      <div class="d-flex">
        <router-link to="/configuracion-de-dashboard">
          <b-button variant="outline-secondary btn_header" class="margin-right">
            <b-icon-house-fill />
          </b-button>
        </router-link>
        <router-link to="/llamados-de-paciente">
          <b-button variant="outline-secondary btn_header" class="margin-right">
            <b-icon-bell-fill />
          </b-button>
        </router-link>
        <b-button variant="outline-secondary btn_header" @click="panelUser('open')">
          <b-icon-person-circle />
        </b-button>
      </div>
    </div>
    <div class="panel__user p-1" :class="{ active: isActive }">
      <div class="d-flex justify-content-end mb-4">
        <div class="btn__closed" @click="panelUser('close')">
          <b-icon-x font-scale="2" />
        </div>
      </div>
      <div class="text-center">
        <div class="profile__name mb-1">{{ getLoggedUser.name }}</div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="profile__button text-center" @click="logout">
          <b-icon-box-arrow-left />
          Cerrar sesión
        </div>
      </div>
    </div>
    <base-modal title="Configuración de panel" :id="id">
      <template v-slot:form >
        <form-setting />
      </template>
    </base-modal>
  </div>
</template>

<script>
import {
  BIconGear,
  BIconBellFill,
  BIconPersonCircle,
  BIconBoxArrowLeft,
  BIconHouseFill,
  BIconX,
  BButton,
} from "bootstrap-vue";
import BaseModal from "../modal/ModalBase";
import FormSetting from "./FormSetting";
import mqtt from "mqtt";
import { optionsMqtt } from "@/config";
import { mapGetters } from "vuex";

export default {
  components: {
    BIconGear,
    BIconBellFill,
    BIconPersonCircle,
    BIconBoxArrowLeft,
    BIconHouseFill,
    BIconX,
    BButton,
    BaseModal,
    FormSetting,
  },
  props:{
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      id: "modal-setting",
      client: null,
      isActive: false,
    }
  },
  computed: {
    ...mapGetters("user", ["getLoggedUser"])
  },
  methods: {
    modalSetting() {
      const me = this;
      me.$bvModal.show(me.id);
    },
    startMqttClient(){
      const me = this;

      const topic = "helpsmart/+/#";
      const connectUrl = "ws://" + optionsMqtt.host + ":" + optionsMqtt.port + optionsMqtt.endpoint;

      try {
        me.client = mqtt.connect(connectUrl, optionsMqtt);
      } catch (error) {
        console.error(error);
      }

      me.client.on("connect", () => {
        // console.log("Connection succeeded");

        me.client.subscribe(topic, {qos:0}, (err) => {
          if(err){
            // console.log("Error in subscription", err);
            return;
          }

          // console.log("Subscription success");
        })
      });

      me.client.on("error", error => {
        //  console.log("Connection failed", error);
      });

      me.client.on("reconnect", (error) => {
        // console.log("Reconnecting", error);
      });

      me.client.on("message", (topic, message) => {
        
        const messageReceived = JSON.parse(message.toString())
        if (!messageReceived.sensor) {
          me.$store.dispatch("message/loadMessages", messageReceived);
          me.$router.push({name: "call", params: { type: messageReceived.llamado[0].name}})
        }
      });
    },
    panelUser(type) {
      type == "open" ? (this.isActive = true) : (this.isActive = false);
    },
    logout() {
      const me = this
      localStorage.clear();
      me.$router.push("/login");
    },
  },
  mounted() {
    // this.startMqttClient()
  },
};
</script>

<style lang="css">
.container-fluid-1 {
  background-color: #29a2cc;
  height: 50px;
}

.title {
  color: var(--white-color);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 32px;
  letter-spacing: 2px;
}

.margin-right {
  margin-right: 10px;
}

.btn_header {
  padding: 4px;
  color: white;
  border: 1px solid white;
  font-size: 18px;
  /* position: relative;
  top: 5px; */
}

.btn_header:hover {
  background-color: rgb(177, 176, 176);
}

.panel__user
{
	width: 250px;
	height: auto;
	background-color: var(--fourth-color);
	right: 15px;
	top: 53px;
	position: fixed;
	z-index: 200;
	transition: .8s ease-in;
	border-radius: .3rem;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  display: none;
}

.btn__closed {
  padding: 0;
  background-color: transparent;
  color: var(--white-color);
  cursor: pointer;
}

.btn__closed:hover {
  background-color: transparent;
  color: var(--white-color);
}

.panel__user.active
{
	display: block;
	transition: .8s ease-out;
}

.profile__name {
  font-size: 20px;
  color: var(--white-color);
  font-family: var(--fuente-econde-sans);
  position: relative;
  top: -35px;
}

.profile__button {
  padding: .3rem;
  background-color: var(--third-color);
  color: var(--white-color);
  font-size: 18px;
  width: 65%;
  border-radius: 1rem;
  position: relative;
  top: -25px;
  cursor: pointer;
}

.profile__button:hover {
  background-color: var(--third-color);
  color: var(--white-color);
}
</style>
