<template>
  <b-modal
    :id="id"
    hide-footer
    centered
    :size="size"
  >
    <template v-slot:modal-header="{ close }">
      <h5>{{ title }}</h5>
      <button class="btn_close" @click="close()">
        <b-icon-x font-scale="2" />
      </button>
    </template>
    <b-container>
      <slot name="form" />
    </b-container>
  </b-modal>
</template>

<script>
import {
  BModal,
  BIconX,
  BContainer,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BIconX,
    BContainer,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      defualt: null,
    },
    size: {
      type: String,
      default: 'lg',
    },
  }
}
</script>

<style lang="css">
  .modal-header
  {
    height: 60px;
    border: none;
  }

  .modal-header h5
  {
    color: var(--black-color);
    font-size: 20px;
    font-weight: 600;
    font-family: var(--fuente-econde-sans);
    line-height: 8px;
  }

  .modal-header .btn_close {
    background-color: var(--primary-color);
    color: var(--white-color);
    font-size: 16px;
    border-radius: 10%;
    font-family: var(--fuente-source-sans-pro);
    letter-spacing: 0.5px;
    font-weight: 600;
    border: none;
    position: relative;
    top: -13px;
  }

  .modal-body
  {
    padding: 5px;
    position: relative;
    top: -20px;
  }
</style>