<template>
  <b-form @submit.prevent="saveData" ref="form">
    <b-row class="mb-2">
      <b-col lg="12" md="12" sm="12">
        <div role="group">
          <label class="label_input">Tipo de dashboard</label>
          <b-form-radio-group
            id="radio-group-1"
            v-model="selected"
            :options="options"
            name="radio-options"
          />
        </div>
      </b-col>
    </b-row>
    <!-- <b-row class="mb-2">
      <b-col lg="6" md="6" sm="12">
        <div role="group">
          <label class="label_input">Sede</label>
          <v-select
            class="style-chooser"
            label="text"
            :options="brands"
            :reduce="option => option.id"
            placeholder="Seleccione sede"
            @input="loadServiceByBrand"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <div role="group">
          <label class="label_input">Servicio</label>
          <v-select
            class="style-chooser"
            v-model="selectedService"
            label="text"
            :options="services"
            :reduce="option => option.id"
            placeholder="Seleccione servicio"
            @input="loadAreaByService"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <div role="group">
          <label class="label_input">Área a visualizar</label>
          <v-select
            class="style-chooser"
            v-model="selectedArea"
            label="text"
            :options="areas"
            :reduce="option => option.id"
            placeholder="Seleccione area"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <div role="group">
          <label for="name" class="label_input">Puerto</label>
          <b-form-input
            id="name"
            size="sm"
            placeholder="Puerto"
          />
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <div role="group">
          <label for="name" class="label_input">Base de datos</label>
          <b-form-input
            id="name"
            size="sm"
            placeholder="Base de datos"
          />
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <div role="group">
          <label for="name" class="label_input">Bróker</label>
          <b-form-input
            id="name"
            size="sm"
            placeholder="Bróker"
          />
        </div>
      </b-col>
    </b-row> -->
    <b-row class="mb-2">
      <b-col cols="12">
        <div class="title_">
          Sonidos de alertas
        </div>
        <audio preload="auto" id="audioSetting">
          <source :src="sound" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
      </b-col>
       <b-col lg="4" md="4" sm="12" class="mb-3">
         <div role="group">
          <label class="label_input">
            Paciente 
            <b-badge @click.prevent="play('1')" v-if="formData.sounPatient && isSounPatient" variant="primary" class="btn_sound"> Play </b-badge>
            <b-badge @click.prevent="stop('1')" v-else-if="formData.sounPatient && !isSounPatient" variant="danger" class="btn_sound"> Stop </b-badge>
          </label>
            <v-select
              :options="sounds"
              v-model="formData.sounPatient"
              label="name"
              class="style-chooser"
              placeholder="Seleccione"
              @input="selectSound(formData.sounPatient, '1')"
              :reduce="option => option.id"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
       </b-col>
       <b-col lg="4" md="4" sm="12" class="mb-3">
         <div role="group">
          <label class="label_input">
            Cód. Azul
            <b-badge @click.prevent="play('2')" v-if="formData.soundBlue && isSoundBlue" variant="primary" class="btn_sound"> Play </b-badge>
            <b-badge @click.prevent="stop('2')" v-else-if="formData.soundBlue && !isSoundBlue" variant="danger" class="btn_sound"> Stop </b-badge>
          </label>
            <v-select
              :options="sounds"
              v-model="formData.soundBlue"
              label="name"
              class="style-chooser"
              placeholder="Seleccione"
              @input="selectSound(formData.soundBlue, '2')"
              :reduce="option => option.id"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
       </b-col>
       <b-col lg="4" md="4" sm="12" class="mb-3">
         <div role="group">
          <label class="label_input">
            Cód. Rojo
            <b-badge @click.prevent="play('3')" v-if="formData.soundRed && isSoundRed" variant="primary" class="btn_sound"> Play </b-badge>
            <b-badge @click.prevent="stop('3')" v-else-if="formData.soundRed && !isSoundRed" variant="danger" class="btn_sound"> Stop </b-badge>
          </label>
            <v-select
              :options="sounds"
              v-model="formData.soundRed"
              label="name"
              class="style-chooser"
              placeholder="Seleccione"
              @input="selectSound(formData.soundRed, '3')"
              :reduce="option => option.id"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
       </b-col>
       <b-col lg="4" md="4" sm="12" class="mb-3">
         <div role="group">
          <label class="label_input">
            Incendio
            <b-badge @click.prevent="play('4')" v-if="formData.soundFire && isSoundFire" variant="primary" class="btn_sound"> Play </b-badge>
            <b-badge @click.prevent="stop('4')" v-else-if="formData.soundFire && !isSoundFire" variant="danger" class="btn_sound"> Stop </b-badge>
          </label>
            <v-select
              :options="sounds"
              v-model="formData.soundFire"
              label="name"
              class="style-chooser"
              placeholder="Seleccione"
              @input="selectSound(formData.soundFire, '4')"
              :reduce="option => option.id"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
       </b-col>
       <b-col lg="4" md="4" sm="12" class="mb-3">
         <div role="group">
          <label class="label_input">
            Sensores
            <b-badge @click.prevent="play('5')" v-if="formData.soundSensor && isSoundSensor" variant="primary" class="btn_sound"> Play </b-badge>
            <b-badge @click.prevent="stop('5')" v-else-if="formData.soundSensor && !isSoundSensor" variant="danger" class="btn_sound"> Stop </b-badge>
          </label>
            <v-select
              :options="sounds"
              v-model="formData.soundSensor"
              label="name"
              class="style-chooser"
              placeholder="Seleccione"
              @input="selectSound(formData.soundSensor, '5')"
              :reduce="option => option.id"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
       </b-col>
       <b-col lg="4" md="4" sm="12" class="mb-3">
         <div role="group">
          <label class="label_input">
            Ulc. presión
            <b-badge @click.prevent="play('6')" v-if="formData.soundSore && isSoundSore" variant="primary" class="btn_sound"> Play </b-badge>
            <b-badge @click.prevent="stop('6')" v-else-if="formData.soundSore && !isSoundSore" variant="danger" class="btn_sound"> Stop </b-badge>
          </label>
            <v-select
              :options="sounds"
              v-model="formData.soundSore"
              label="name"
              class="style-chooser"
              placeholder="Seleccione"
              @input="selectSound(formData.soundSore, '6')"
              :reduce="option => option.id"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
       </b-col>
       <b-col lg="4" md="4" sm="12" class="mb-3">
         <div role="group">
          <label class="label_input">
            Baño 
            <b-badge @click.prevent="play('7')" v-if="formData.soundBath && isSoundBath" variant="primary" class="btn_sound"> Play </b-badge>
            <b-badge @click.prevent="stop('7')" v-else-if="formData.soundBath && !isSoundBath" variant="danger" class="btn_sound"> Stop </b-badge>
          </label>
            <v-select
              :options="sounds"
              v-model="formData.soundBath"
              label="name"
              class="style-chooser"
              placeholder="Seleccione"
              @input="selectSound(formData.soundBath, '7')"
              :reduce="option => option.id"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
       </b-col>
    </b-row>
    <!-- <b-row>
      <b-col cols="12" class="d-flex justify-content-end mt-2">
        <b-button
          type="submit"
          class="btn__guardar"
        >
          <b-spinner v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row> -->
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BForm,
  BFormRadioGroup,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";

export default {
  inject: ['patientBedRepository'],
  components: {
    BCol,
    BRow,
    BForm,
    BBadge,
    BFormRadioGroup,
    vSelect,
  },
  props: {},
  data() {
    return {
      isBusy: false,
      isSounPatient: false,
      isSoundBlue: false,
      isSoundRed: false,
      isSoundFire: false,
      isSoundSensor: false,
      isSoundSore: false,
      isSoundBath: false,
      formData: {
        sounPatient: null,
        soundBlue: null,
        soundRed: null,
        soundFire: null,
        soundSensor: null,
        soundSore: null,
        soundBath: null,
      },
      selected: 2,
      options: [
        { text: 'Configurable', value: 1 },
        { text: 'Estándar', value: 2 },
      ],
      selectedService: null,
      selectedArea: null,
      selectedSubarea: null,
      sound: null,
      brands: [],
      services: [],
      areas: [],
      subareas: [],
      locationPatients: [],
      sounds: [
        {
          id: 1,
          name: "Sonido 1",
          sound: require("@/assets/audio/1.aac"),
        },
        {
          id: 2,
          name: "Sonido 2",
          sound: require("@/assets/audio/2.aac"),
        },
        {
          id: 3,
          name: "Sonido 3",
          sound: require("@/assets/audio/3.aac"),
        },
        {
          id: 4,
          name: "Sonido 4",
          sound: require("@/assets/audio/4.aac"),
        },
        {
          id: 5,
          name: "Sonido 5",
          sound: require("@/assets/audio/5.aac"),
        },
        {
          id: 6,
          name: "Sonido 6",
          sound: require("@/assets/audio/6.aac"),
        },
        {
          id: 7,
          name: "Sonido 7",
          sound: require("@/assets/audio/7.aac"),
        },
        {
          id: 8,
          name: "Sonido 8",
          sound: require("@/assets/audio/8.aac"),
        },
        {
          id: 9,
          name: "Sonido 9",
          sound: require("@/assets/audio/9.aac"),
        },
        {
          id: 10,
          name: "Sonido 10",
          sound: require("@/assets/audio/10.aac"),
        },
        {
          id: 11,
          name: "Sonido 11",
          sound: require("@/assets/audio/11.aac"),
        },
        {
          id: 12,
          name: "Sonido 12",
          sound: require("@/assets/audio/12.aac"),
        },
        {
          id: 13,
          name: "Sonido 13",
          sound: require("@/assets/audio/13.aac"),
        }
      ],
    }
  },
  validations: {},
  mounted() {
    const me = this;

    // me.loadBrandByClient();
    me.loadSounds();
  },
  methods: {
    saveData() {},
    async loadBrandByClient() {
      const me = this
      try {
        const res = await me.patientBedRepository.getAllBrandByClient(me.customerId)
        res.forEach(element => {
          me.brands.push({ id: element.id, text: element.name })
        })
      } catch (error) {
        // console.log(error)
      }
    },
    async loadServiceByBrand(id) {
      const me = this
      try {
        const { data }  = await me.patientBedRepository.getAllServicesByBrand(id)
        if (data.length > 0) {
          data.forEach(el => {
            me.services.push({ id: el.id, text: el.name })
          })
        } else {
          me.$bvToast.toast('No existen servicios registrado para la sede seleccionada', {
            title: 'Sin servicios',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            solid: true,
            appendToast: true,
          })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async loadAreaByService(id) {
      const me = this
      try {
        const { data } = await me.patientBedRepository.getAllAreaByService(id);

        data.forEach(el => {
          me.areas.push({ id: el.id, text: el.name })
        })
      } catch (error) {
        me.$bvToast.toast('No existen areas registrado para el servicio seleccionado', {
          title: 'Sin areas',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger',
          solid: true,
          appendToast: true,
        })
        // console.log(error)
      }
    },
    async loadSubareaByArea(id) {
      const me = this
      try {
        const { data } = await me.patientBedRepository.getAllSubareaByArea(id);
        
        data.forEach(el => {
          me.subareas.push({ id: el.id, text: el.name })
        })
      } catch (error) {
        me.$bvToast.toast('No existen subareas registrado para el area seleccionada', {
          title: 'Sin subareas',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger',
          solid: true,
          appendToast: true,
        })
        // console.log(error)
      }
    },
    async loadLocationPatient(id) {
      const me = this
      try {
        me.locationPatients = await me.patientBedRepository.getAllLocationPatient(id)
      } catch (error) {
        me.locationPatients = []
        // console.log(error)
        me.$bvToast.toast('No existen camas registradas para el subarea seleccionada', {
          title: 'Sin camas',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger',
          solid: true,
          appendToast: true,
        })
      }
    },
    selectSound(id, type) {
      const me = this;
      let nameObject = null;

      const audio = document.querySelector("audio");
      const audioSelect = me.sounds.find(el => el.id == id);

      if (type == "1") {

        me.isSounPatient = false;
        nameObject = "sounPatient";

      } else if (type == "2") {

        me.isSoundBlue = false;
        nameObject = "soundBlue";

      } else if (type == "3") {

        me.isSoundRed = false;
        nameObject = "soundRed";

      } else if (type == "4") {

        me.isSoundFire = false;
        nameObject = "soundFire";

      } else if (type == "5") {

        me.isSoundSensor = false;
        nameObject = "soundSensor";

      } else if (type == "6") {

        me.isSoundSore = false;
        nameObject = "soundSore";

      } else  {

        me.isSoundBath = false;
        nameObject = "soundBath";

      }

      me.sound = audioSelect.sound;

      localStorage.setItem(nameObject, JSON.stringify({
        audio: audioSelect.sound,
        id: id,
      }));

      audio.load()
      audio.play();
    },
    play(type) {
      const me = this;
      const audio = document.querySelector("audio");

      if (type == "1") {

        me.isSounPatient = false;
        me.sound = me.sounds.find(el => el.id == me.formData.sounPatient).sound;

      } else if (type == "2") {

        me.isSoundBlue = false;
        me.sound = me.sounds.find(el => el.id == me.formData.soundBlue).sound;

      } else if (type == "3") {

        me.isSoundRed = false;
        me.sound = me.sounds.find(el => el.id == me.formData.soundRed).sound;

      } else if (type == "4") {

        me.isSoundFire = false;
        me.sound = me.sounds.find(el => el.id == me.formData.soundFire).sound;

      } else if (type == "5") {

        me.isSoundSensor = false;
        me.sound = me.sounds.find(el => el.id == me.formData.soundSensor).sound;

      } else if (type == "6") {
        
        me.isSoundSore = false;
        me.sound = me.sounds.find(el => el.id == me.formData.soundSore).sound;

      } else  {

        me.isSoundBath = false;
        me.sound = me.sounds.find(el => el.id == me.formData.soundBath).sound;

      }

      audio.load();
      audio.play();
    },
    stop(type) {
      const me = this;
      const audio = document.querySelector("audio");
      me.sound = null;

      if (type == "1") {

        me.isSounPatient = true;

      } else if (type == "2") {

        me.isSoundBlue = true;

      } else if (type == "3") {

        me.isSoundRed = true;

      } else if (type == "4") {

        me.isSoundFire = true;

      } else if (type == "5") {

        me.isSoundSensor = true;

      } else if (type == "6") {

        me.isSoundSore = true;

      } else  {

        me.isSoundBath = true;

      }

      audio.pause();
    },
    loadSounds() {
      const me = this;

      if (localStorage.getItem("sounPatient")) {

        const audio = JSON.parse(localStorage.getItem("sounPatient"));
        me.formData.sounPatient = audio.id;
        me.isSounPatient = true;

      } 
      
      if (localStorage.getItem("soundBlue")) {

        const audio = JSON.parse(localStorage.getItem("soundBlue"));
        me.formData.soundBlue = audio.id;
        me.isSoundBlue = true;

      } 
      
      if (localStorage.getItem("soundRed")) {

        const audio = JSON.parse(localStorage.getItem("soundRed"));
        me.formData.soundRed = audio.id;
        me.isSoundRed = true;

      } 
      
      if (localStorage.getItem("soundFire")) {

        const audio = JSON.parse(localStorage.getItem("soundFire"));
        me.formData.soundFire = audio.id;
        me.isSoundFire = true;

      } 
      
      if (localStorage.getItem("soundSensor")) {

        const audio = JSON.parse(localStorage.getItem("soundSensor"));
        me.formData.soundSensor = audio.id;
        me.isSoundSensor = true;

      } 
      
      if (localStorage.getItem("soundSore")) {

        const audio = JSON.parse(localStorage.getItem("soundSore"));
        me.formData.soundSore = audio.id;
        me.isSoundSore = true;

      }

      if (localStorage.getItem("soundBath")) {

        const audio = JSON.parse(localStorage.getItem("soundBath"));
        me.formData.soundBath = audio.id;
        me.isSoundBath = true;

      }
    },
  },
}
</script>

<style lang="css">
.btn_sound {
  cursor: pointer !important;
}
</style>